import React from "react";

import Container from "../container/Container";

import PageText from "../../constants/PageText";

import "./UiPageText.scss";

const PageTextBlock = (props) => {
  if (props.type === "list") {
    var list = props.list.map((item, index) => {
      return <li key={index}>{item}</li>;
    });
  }
  if (props.type === "list2") {
    var list2 = props.list.map((item, index) => {
      return <li key={index}>{item}</li>;
    });
  }
  return (
    <div className="page-text-block">
      {props.type === "text" && <p>{props.text}</p>}
      {props.type === "title" && <h1>{props.text}</h1>}
      {props.type === "list" && <ul>{list}</ul>}
      {props.type === "list2" && <ol>{list2}</ol>}
    </div>
  );
};

const UiPageText = (props) => {
  if (props.page === "home") {
    var items0 = PageText.HOME_TEXT.map((item, index) => {
      return (
        <PageTextBlock
          key={index}
          type={item.type}
          text={item.text}
          list={item.list}
        />
      );
    });
  }

  if (props.page === "catalog") {
    var items1 = PageText.CATALOG_TEXT.map((item, index) => {
      return (
        <PageTextBlock
          key={index}
          type={item.type}
          text={item.text}
          list={item.list}
        />
      );
    });
  }

  if (props.page === "rozy") {
    var items2 = PageText.ROZY_TEXT.map((item, index) => {
      return (
        <PageTextBlock
          key={index}
          type={item.type}
          text={item.text}
          list={item.list}
        />
      );
    });
  }

  if (props.page === "piony") {
    var items3 = PageText.PIONY_TEXT.map((item, index) => {
      return (
        <PageTextBlock
          key={index}
          type={item.type}
          text={item.text}
          list={item.list}
        />
      );
    });
  }

  if (props.page === "avtorskie-bukety") {
    var items4 = PageText.AVTOR_TEXT.map((item, index) => {
      return (
        <PageTextBlock
          key={index}
          type={item.type}
          text={item.text}
          list={item.list}
        />
      );
    });
  }

  return (
    <section className="page-text">
      <Container>
        {props.page === "home" && items0}
        {props.page === "catalog" && items1}
        {props.page === "rozy" && items2}
        {props.page === "piony" && items3}
        {props.page === "avtorskie-bukety" && items4}
      </Container>
    </section>
  );
};
export default UiPageText;
