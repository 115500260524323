import React from "react";

import "./UiBtnColor.scss";

const UiBtnColor = (props) => {
  if(props.href){
    return (
      <a
        className={
          "btn" +
          (props.color ? " btn-" + props.color : "") +
          (props.disabled ? " disabled" : "") +
          (props.small ? " small" : "") + 
          (props.error ? " error" : "")
        }
        href={props.href}
      >
        <span>{props.text}</span>
      </a>
    );
  } else {
    return (
      <button
        className={
          "btn" +
          (props.color ? " btn-" + props.color : "") +
          (props.disabled ? " disabled" : "") +
          (props.small ? " small" : "") + 
          (props.error ? " error" : "")
        }
        disabled={props.disabled}
        onClick={() => props.onClick()}
      >
        <span>{props.text}</span>
      </button>
    );
  }
  
};
export default UiBtnColor;
