import ENV from '../services/Env.js';




export const regUser = (_phone, _code, _token) => {
  return fetch(`${ENV.API_URL}/users/store`, {
    method: "POST",
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      phone: _phone,
      captcha: _code,
      token: _token,
    }),
  }).then(function (response) {
    return response.json();
  });
};


export const checkLogin = (log, pass) => {

  return fetch(ENV.API_URL + '/users/login', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      password: pass,
      phone: log,
    }),
  }).then((response) => {
    return response.json()
  });
}


export const getUsers = (_api) => {

  return fetch(ENV.API_URL + '/users/all', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _api
    }),
  }).then((response) => {
    return response.json()
  });
}

export const registerMaxmaUser = (_user) => {

  return fetch(`${ENV.API_URL}/users/discount/get/${_user.id}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _user.api_token
    }),
  }).then((response) => {
    return response.json()
  });
}




export const getUser = (_api, _id, _ni = false) => {
  let _data = {
    api_token: _api,
    id: _id,
  }
  if (_ni) {
    _data = {
      api_token: _api,
      id: _id,
      no_ip: true
    }
  }
  return fetch(`${ENV.API_URL}/users/show/${_id}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data),
  }).then((response) => {
    return response.json()
  });
}

export const getUserNotification = (_api, _id) => {

  return fetch(ENV.API_URL + '/users/notifications/list', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _api,
      uid: _id,
    }),
  }).then((response) => {
    return response.json()
  });
}


export const getUserRating = (_api) => {

  return fetch(ENV.API_URL + '/users/rating/top', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _api,
    }),
  }).then((response) => {
    return response.json()
  });
}


export const readUserNotification = (_api, _id) => {

  return fetch(ENV.API_URL + '/users/notifications/read', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _api,
      uid: _id,
    }),
  }).then((response) => {
    return response.json()
  });
}




export const confirmUser = (_token) => {
  return fetch(ENV.API_URL + '/users/confirm', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      token: _token,
    })
  }).then(function (response) {
    return response.json();
  });
}


export const editUser = (_data) => {

  return fetch(`${ENV.API_URL}/users/update/${_data.id}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}



export const addNotification = (_api, _type, _value, _text) => {
  return fetch(ENV.API_URL + '/users/notifications/add', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify({
      api_token: _api,
      type: _type,
      value: _value,
      text: _text
    })
  }).then(function (response) {
    return response.json();
  });
}


 