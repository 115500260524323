import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgCircle } from "../../assets/logo/circle.svg";
import SvgLogo from "../../assets/logo/logo.webp";

import {
  getStoredUser as getStoredUserAction,
  getStoredBasket as getStoredBasketAction,
} from "../../redux/modules/login";
import { getTopMenuCategoryList as getTopMenuCategoryListAction } from "../../redux/modules/category";
import { retrieveData, storeData } from "../../services/Storage";

import Container from "../container/Container";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiIcon from "../icon/UiIcon";
import UiMenuList from "../menu/UiMenuList";

import "./UiHeader.scss";
import UiBtnColor from "../buttons/UiBtnColor";
import { getTotalPrice } from "../../utilities/order";

const UiHeader = (props) => {
  let navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [basket, setBasket] = useState([]);
  const [menuList, setMenuList] = useState([]);

  const setMenuOpen = () => {
    let new_v = !dropdownOpen;
    setDropdownOpen(new_v);
    props.onChangeMenu();
  };

  useEffect(() => {
    if (props.login.basketList) {
      setBasket(props.getStoredBasket());
    }
  }, [props.login.basketList]);

  useEffect(() => {
    props.getStoredUser().then((user) => {
      if (user) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    });
  }, []);

  useEffect(() => {
    setMenuList(
      retrieveData("menu_flowery")
        ? retrieveData("menu_flowery")
        : props.menuList1
    );
    props.getTopMenuCategoryList().then((res) => {
      let arr = res.map((c) => {
        return {
          page:
            c.sub_list.length > 0
              ? `/group/${c.title_url}`
              : `/catalog/${c.title_url}`,
          title: c.title,
          icon: c?.icon,
          //subList: c.sub_list.length > 0 ?  c.sub_list.map((i) =>{ return  { page:  `/catalog/${i.title_url}`, title: i.title } }) : null
          subList: null,
        };
      });
      storeData("menu_flowery", arr);
      setMenuList(arr);
    });
  }, []);

  return (
    <header id="header" className="header-wrap">
      <div className="header-info-wrap">
        <Container>
          <div className="header-info">
            <a
              href="https://yandex.ru/maps/80/yuzhno-sakhalinsk/chain/flowery/177239590117/?ll=142.740275%2C46.954665&sll=142.740275%2C46.954656&z=13"
              className="header-info-item"
            >
              <UiIcon fill="pink" icon="pin" size={16} />
              <p>Южно-Сахалинск</p>
            </a>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="clock" size={16} />
              <p>Круглосуточная доставка</p>
            </div>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="checked-box" size={16} />
              <p>от 60 мин</p>
            </div>
            <div className="header-info-social">
              <div className="nav-menu-rating">
                <a
                  href="https://yandex.ru/maps/org/flowery/53422456934/reviews/?ll=142.733023%2C46.964264&z=15"
                  className="nav-menu-rating-btn"
                >
                  <UiIcon icon="yandex" size={13} />
                  <span>5.0</span>
                </a>
                <a
                  href="https://www.google.com/maps/place/Flowery/@46.9643381,142.7310849,17z/data=!3m1!4b1!4m5!3m4!1s0x5f19aa741a960f33:0x105bdf1f6c5e6850!8m2!3d46.9643381!4d142.7332736?hl=ru-RU"
                  className="nav-menu-rating-btn"
                >
                  <UiIcon icon="google-g" size={14} />
                  <span>4.8</span>
                </a>
                <a href="/reviews" className="nav-menu-rating-btn">
                  <UiIcon icon="2gis" size={14} />
                  <span>4.4</span>
                </a>
              </div>
              <UiBtnIcon
                color=""
                fill="white"
                icon="whatsapp"
                iconSize={24}
                size={24}
                href="https://api.whatsapp.com/send/?phone=79147541334"
              />
              <UiBtnIcon
                color=""
                fill="white"
                icon="telegram"
                iconSize={24}
                size={24}
                href="https://t.me/Flowerybot"
              />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="header-top">
          <UiMenuList menuList={props.menuList2} />
          <UiMenuList menuList={props.menuList3} />
        </div>
      </Container>
      <Container>
        <div className="header">
          <div className="header-main">
            <div className="header-main-wrap">
              <a className="btn header-logo" href="/">
                <img
                  className="header-logo-icon"
                  src={SvgLogo}
                  height={72}
                  width={237}
                  title="Flowery"
                  alt="Flowery"
                />
              </a>
              <div className="header-about">
                <div className="header-about-item">
                  <p>
                    <UiIcon fill="pink" icon="pin2" size={12} />
                    <strong>Южно-Сахалинск</strong>
                  </p>
                </div>
                <div className="header-about-item">
                  <UiIcon fill="pink" icon="clock2" size={12} />
                  <p>Время работы с 6:00 до 01:00</p>
                </div>
                <div className="header-about-item">
                  <p>
                    <strong>Доставка - круглосуточно</strong>
                  </p>
                </div>
              </div>
              <div className="header-about">
                <a className="header-about-phone" href="tel:88003334409">
                  +7 (800) 333-44-09
                </a>
                <UiBtnColor
                  color="pink"
                  small
                  text="Заказать звонок"
                  onClick={() => props.onFeedBackOpen()}
                />
              </div>
            </div>
            <div className="header-basket-wrap">
              <UiBtnIcon
                fill={basket.length > 0 ? "white" : "pink"}
                color={basket.length > 0 ? "pink" : "white"}
                icon="bag"
                iconSize={20}
                size={42}
                onClick={() => navigate("/order")}
              />
              <div className="header-basket-info">
                <p>
                  <b>{getTotalPrice(basket)} руб.</b>
                </p>
                <p>{basket.length} товаров</p>
              </div>
            </div>
          </div>
          <nav
            className={
              "nav-menu nav-menu-main" + (dropdownOpen ? " active" : "")
            }
          >
            <SvgCircle className="nav-menu-mobile-cover1" />
            <SvgCircle className="nav-menu-mobile-cover2" />
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <a className="btn header-logo" href="/">
                  <img
                    className="header-logo-icon"
                    src={SvgLogo}
                    height={138}
                    width={42}
                    title="Flowery"
                    alt="Flowery"
                  />
                </a>
                <UiBtnIcon
                  color="blue"
                  fill="white"
                  icon="close"
                  iconSize={12}
                  size={42}
                  onClick={() => setMenuOpen()}
                />
              </div>
            </div>
            <div>
              <div className="nav-menu-mobile">
                <UiMenuList menuList={props.menuList2} />
                <UiMenuList menuList={props.menuList3} />
              </div>
            </div>
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <div className="header-about">
                  <p>Позвонить нам</p>
                  <a className="header-about-phone" href="tel:88003334409">
                    +7 (800) 333-44-09
                  </a>
                </div>
                <div className="header-info-social">
                  <UiBtnIcon
                    color=""
                    fill="green"
                    icon="whatsapp"
                    iconSize={36}
                    size={36}
                  />
                  <UiBtnIcon
                    color=""
                    fill="blue"
                    icon="telegram"
                    iconSize={36}
                    size={36}
                  />
                </div>
              </div>
            </div>
          </nav>
          <div className="nav-menu-button">
            <UiBtnIcon
              color=""
              fill="pink"
              icon="menu"
              iconSize={20}
              size={48}
              onClick={() => setMenuOpen()}
            />
          </div>
        </div>
      </Container>
      <div
        className={
          "header-categories" +
          (props.stick ? " stick" : "") +
          (dropdownOpen ? " menu-open" : "")
        }
      >
        <Container>
          <nav className={"nav-menu nav-menu-categories"}>
            <UiMenuList allVisible menuList={menuList} />
            <div className={"header-profile" + (props.stick ? " stick" : "")}>
              <UiBtnIcon
                fill="pink"
                icon="search"
                iconSize={20}
                size={32}
                onClick={() => props.onSearchOpen()}
              />
              <UiBtnIcon
                fill={"pink"}
                color={""}
                icon="heart"
                iconSize={20}
                size={32}
                onClick={() => navigate("/favorite")}
              />
              <UiBtnIcon
                fill="pink"
                icon="user"
                iconSize={20}
                size={32}
                onClick={() =>
                  isLogin ? navigate("/profile") : navigate("/login")
                }
              />
            </div>
          </nav>
        </Container>
      </div>
    </header>
  );
};

export default connect(({ login }) => ({ login }), {
  getStoredUser: getStoredUserAction,
  getStoredBasket: getStoredBasketAction,
  getTopMenuCategoryList: getTopMenuCategoryListAction,
})(UiHeader);
