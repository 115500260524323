import React from "react";

import "./UiTextArea.scss";

const UiTextArea = (props) => {
  return (
    <div className="form-group">
      <label>{props.label}</label>
      <textarea
        disabled={props.disabled}
        placeholder={props.placeholder}
        rows={props.rows ? props.rows : "3"}
        className="text-area"
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      ></textarea>
    </div>
  );
};
export default UiTextArea;
