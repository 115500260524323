export default {
  HOME_TEXT: [
    {
      type: "text",
      text: "Мы создаем стильные авторские букеты, чтобы с помощью цветов передать радостные эмоции, теплые воспоминания, трепетные чувства. Наши профессиональные флористы помогут не растеряться в окружении цветочного разнообразия, подскажут с выбором и создадут эксклюзивные композиции специально для вас!",
    },
    {
      type: "title",
      text: "Покупать цветы в цветочной лавке Flowery легко и приятно!",
    },
    {
      type: "text",
      text: "У нас:",
    },
    {
      type: "list",
      list: [
        "удобная форма заказа в интернет-магазине;",
        "гарантия на цветы 96 часов;",
        "букеты, полностью соответствующие фотографиям на сайте;",
        "7 розничных магазинов;",
        "накопительные бонусы за каждый заказ, позволяющие экономить на следующих покупках;",
        "быстрая круглосуточная доставка в течение 60 минут или точно к необходимому времени;",
        "флористы с хорошим вкусом, создающие авторские букеты для особенных событий;",
        "всегда в наличии большой ассортимент свежих цветов высокого качества благодаря прямым еженедельным поставкам с плантаций Кении и Эквадора.",
      ],
    },
    {
      type: "text",
      text: "Цветы – это символ любви, заботы и внимания, уважения и благодарности. Они создают особую атмосферу легкости и радости, поднимают настроение и делают особенным любой день.  Закажите букет в цветочной лавке Flowery – насладитесь красотой и ароматом свежих цветов, счастливыми улыбками любимых людей.",
    },
    { type: "title", text: "А мы сделаем все для эффектного сюрприза:" },
    {
      type: "list",
      list: [
        "пришлем фото букета для согласования перед отправкой;",
        "распечатаем памятное фото и подпишем открытку с теплыми пожеланиями;",
        "дополним цветочный презент приятными мелочами в виде шоколада, мягкой игрушки или воздушных шаров;",
        "используем транспортировочный бокс, что доставить цветы в идеальном состоянии;",
        "сохраним анонимность подарка, если это необходимо;",
        "самостоятельно узнаем точный адрес и удобное для получателя время.",
      ],
    },
    {
      type: "text",
      text: "Доставка цветов возможна не только в пределах Южно-Сахалинска, но и в ближайшие города Сахалинской области.",
    },
  ],
  CATALOG_TEXT: [
    {
      type: "text",
      text: "Выбрать и купить цветы, которые смогут выразить те самые чувства, не всегда легко. Цветочная лавка Flowery поможет сделать выбор и соберет идеальный букет для особенного случая. Приоритетное направление для нас – авторская флористика. Мы творим от души и с любовью, чтобы каждый цветок в композиции дарил определенные эмоции.",
    },
    {
      type: "title",
      text: "Для какого случая подойдут авторские букеты?",
    },
    {
      type: "text",
      text: "Яркие и оригинальные, элегантные и статусные, чувственные и очаровательные, нежные и трепетные – наши букеты станут великолепным подарком в честь торжественного события или приятным сюрпризом, чтобы порадовать любимого человека. Подойдут для свадьбы, юбилея, выпускного, дня рождения, 8 Марта или предложения руки и сердца, поздравления с повышением по службе, рождением ребенка, новосельем, творческими или профессиональными успехами.",
    },
    {
      type: "title",
      text: "Почему выбирают нас?",
    },
    {
      type: "list",
      list: [
        "•	Высокий рейтинг и многочисленные положительные отзывы подтверждают великолепное качество цветов и отличный сервис.",
        "•	Реальные букеты максимально соответствуют фото в интернет-магазине, а перед отправкой мы всегда согласовываем внешний вид цветов с клиентом.",
        "•	Собственный штат курьеров позволяет сделать доставку быстрой и бережной, всего за 60 минут мы доставим букет получателю в Южно-Сахалинске.",
        "•	Заказывая цветы онлайн, вы можете выбрать удобный вариант оплаты: картой на сайте или наличными и картой курьеру при получении.",
        "•	Выгодные цены, бонусы и акции позволяющие чаще дарить счастливые моменты близким людям.",
        "•	Часы работы магазинов и клиентской службы позволяют практически в любое время оформить заказ.",
      ],
    },
    {
      type: "title",
      text: "Какие цветы и подарки можно найти в каталоге?",
    },
    {
      type: "text",
      text: "Мы используем в своих букетах множество цветов — от классических до экзотических: розы, хризантемы, герберы, гортензии, лилии, альстромерии, гвоздики, гипсофилы, тюльпаны, пионы, ранункулюсы и другие. А еще добавляем декоративные элементы, такие как ленты, корзины, дизайнерская упаковочная бумага. Все это поможет сделать цветочные композиции еще более красивыми и запоминающимися.",
    },
    {
      type: "text",
      text: "Дополнить эксклюзивный букет можно разнообразными подарками из интернет-каталога: милой мягкой игрушкой, яркими воздушными шарами, красивой открыткой, изысканным шоколадом, элегантной вазой или диффузором с уникальными ароматами Flowery для дома.",
    },
    {
      type: "text",
      text: "Кроме цветочных композиций из срезанных цветов, у нас можно купить горшечные растения, которые круглый год будут радовать зеленым видом, придавать уют и очищать воздух в помещении.",
    },
  ],
  ROZY_TEXT: [
    {
      type: "text",
      text: "Элегантная и несравненная роза — королева среди растений. В каталоге Flowery представлены цветы премиум-класса из Кении и Эквадора. Мы выбираем сорта Мондиал, Фридом, Венделла, заслужившие свою популярность благодаря эффектному внешнему виду и хорошей стойкости.",
    },
    {
      type: "text",
      text: "Готовы предложить розы-гиганты с длиной стебля до 1 м или стандартных размеров 50–70 см. С крупными или более утонченными бутонами, белого и красного цвета, с насыщенным или тонким, едва уловимым ароматом.",
    },
    {
      type: "text",
      text: "Кроме того, в ассортименте представлены очаровательные кустовые розы, с хрупкими миниатюрными бутонами нежных и ярких оттенков: розовые, сиреневые, кремовые, желтые оранжевые, красные. ",
    },
    { type: "title", text: "Символизм" },
    {
      type: "text",
      text: "Букет алых роз поможет выразить восхищение, привязанность, страсть, а белые розы, подаренные в начале отношений, станут символом зарождающейся любви и расскажут об искренних романтических чувствах.",
    },
    {
      type: "text",
      text: "Огромный букет из 71, 101 или 151 цветка можно преподнести на помолвку или годовщину свадьбы, приятно шокировать избранницу и надолго оставить воспоминание об этом счастливом событии.",
    },
    {
      type: "text",
      text: "Помимо романтики, красные и белые розы принято дарить в знак благодарности, уважения и признательности, уместно покупать их в подарок на юбилеи или другие торжественные мероприятия, например, школьный выпускной или выписку из роддома.",
    },
    { type: "title", text: "Как продлить жизнь срезанных роз дома?" },
    {
      type: "list2",
      list: [
        "Наполните вазу чистой, лучше фильтрованной водой, без содержания хлора. Добавьте подкормку в виде сахара или специального средства, так цветы получат необходимые питательные вещества, чтобы дольше оставаться свежими.",
        "Держите розы в прохладном месте, без воздействия прямых солнечных лучей, так удастся замедлить процесс их увядания.",
        "Регулярно меняйте воду в вазе и удаляйте увядшие листья и цветки – это поможет продлить жизнь букета.",
      ],
    },
    {
      type: "text",
      text: "Хотите сделать приятный сюрприз и купить розы с доставкой в Южно-Сахалинске? С нами это получится легко, быстро и недорого! Собственный штат курьеров доставляет заказы круглосуточно в течение 1 часа или к точно обозначенному времени, а при стоимости заказа от 2500 рублей еще и бесплатно. Дарим 500 рублей за первый онлайн-заказ, а за каждый последующий начисляем накопительные бонусы, которыми также можно воспользоваться для покупки цветов.",
    },
    {
      type: "text",
      text: "Закажите роскошный букет в цветочном интернет-магазине Flowery в Южно-Сахалинске – доставьте удовольствие дорогим вашему сердцу женщинам: любимой, маме, сестре, подруге или бабушке. Поставки 3 раза в неделю гарантируют великолепный внешний вид и высокое качество цветов.",
    },
  ],
  PIONY_TEXT: [
    {
      type: "text",
      text: "Перед красотой и очарованием пионов сложно устоять. Округлые густые бутоны нежных оттенков – мечта каждой девушки. Сладкоежки непременно обратят внимание на внешнее сходство цветков с аппетитным десертом, украшенным взбитыми сливками или ягодным суфле. Да и невероятный аромат не оставляет шансов не влюбиться в них.",
    },
    {
      type: "text",
      text: "Еще в Древнем Китае пионы считались особенными, они были недоступны для большинства людей, ими могли любоваться лишь императоры в собственных садах. До сих пор в этой стране их преподносят с пожеланием богатства и процветания, а присутствие в доме создает уютную и теплую атмосферу.",
    },
    {
      type: "text",
      text: "Эти цветы обожают знаменитости и флористы, невесты и дизайнеры интерьера. Крайне сложно найти тех, кто не будет подвержен их очарованию. Последние 7–8 лет они переживают настоящий пик популярности, оставляя на вторых ролях даже розы.",
    },
    {
      type: "text",
      text: "Летом пионы украшают всевозможные торжественные события: свадебные церемонии, школьные выпускные, презентации, юбилеи. Их пышные соцветия могут достигать до 25 см в диаметре, а широкая палитра оттенков представлена белым, нежно-розовым, сиреневым, красным, желтым и даже фиолетовым. Объемность цветков позволяет создать полноценный букет, используя всего несколько пионов, тем самым снижая его цену.",
    },
    { type: "title", text: "Когда можно купить пионы?" },
    {
      type: "text",
      text: "Период их цветения длится с конца весны до начала осени — успейте порадовать любимых прелестными букетами. В каталоге цветочной лавки Flowery в Южно-Сахалинске представлены пионы разного цвета, но все неизменно прекрасные и свежие.",
    },
    {
      type: "text",
      text: "Благородная внешность делает эти цветы великолепным подарком для любого случая, который вызовет восторг у женщин любого возраста. Пионы — это лучший способ сказать «люблю» на языке цветов. Такой знак внимания будет особенно запоминающимся и трогательным.",
    },
    { type: "title", text: "Как сохранить пионы свежими?" },
    {
      type: "list",
      list: [
        "Цветы не любят высоких температур, поэтому их следует разместить в прохладном месте подальше от отопительных приборов или попадания прямых солнечных лучей.",
        "Смена воды каждые 1–2 дня поможет продлить жизнь букета.",
        "Используйте специализированную подкормку для цветов.",
        "Удаляйте увядшие листья и цветки.",
      ],
    },
    { type: "title", text: "Почему стоит заказать пионы у нас?" },
    {
      type: "text",
      text: "Купить букет из пионов в Южно-Сахалинске легко и удобно в цветочном интернет-магазине Flowery! Закажите доставку понравившихся цветов на сайте, а мы пришлем фото готового букета и отправим его лишь после подтверждения.",
    },
    {
      type: "text",
      text: "Собственный штат курьеров гарантирует доставку цветов в идеальном состоянии и помогает доставлять заказы круглосуточно всего за 60 минут или к точно обозначенному времени. А при стоимости заказа от 2500 рублей услуга бесплатна.",
    },
    {
      type: "text",
      text: "Если хотите приобрести и вручить великолепный букет лично, посетите один из 7 наших магазинов – профессиональные флористы помогут с выбором и создадут уникальную композицию именно для вас.",
    },
  ],
  AVTOR_TEXT: [
    {
      type: "text",
      text: "Авторские букеты — это уникальные композиции: выглядят необычно, стильно, ярко. Создаются флористами из различных цветов, экзотических растений, трав и других природных материалов. Это элитный подарок для людей, предпочитающих оригинальность и творчество, обладающих особенным восприятием и вкусом.",
    },
    { type: "title", text: "Преимущества авторских букетов" },
    {
      type: "list",
      list: [
        "Они более индивидуальны и отражают особенности личности получателя, учитывают его предпочтения.",
        "Демонстрируют особенное отношение.",
        "Содержат большое количество деталей, позволяя выразить палитру эмоций и чувств.",
        "Более долговечны и могут стоять в вазе продолжительное время.",
        "Их можно преподнести как женщинам, так и мужчинам.",
      ],
    },
    { type: "title", text: "Кому подарить?" },
    {
      type: "text",
      text: "Такие букеты особенно понравятся тем, кто ценит уникальность. Эксклюзивная композиция станет великолепным украшением интерьера и не оставит равнодушным того, кому предназначена. ",
    },
    { type: "text", text: "Авторские букеты можно дарить:" },
    {
      type: "list",
      list: [
        "на день рождения возлюбленным, мамам, дочерям, бабушкам, друзьям;",
        "День учителя или любые другие профессиональные праздники;",
        "8 Марта, 14 Февраля, День Матери, Новый год;",
        "на окончание школы или университета, рождение ребенка или новоселье;",
        "в качестве благодарности за оказанную помощь;",
        "как особый знак внимания без конкретного повода;",
        "сотрудникам, клиентам, партнёрам;",
        "на свадьбу, юбилей, годовщину, торжественные мероприятия, связанные с карьерными успехами;",
        "творческим людям — артистам, художникам, писателям, модельерам, музыкантам.",
      ],
    },
    {
      type: "text",
      text: "Купите эксклюзивный букет, составленный из необычного сочетания цветов,  — он точно вызовет бурю эмоций и благодарности. ",
    },
    { type: "title", text: "Как сохранить букет свежим?" },
    {
      type: "text",
      text: "Для продления жизни флористы рекомендуют придерживаться нескольких простых правил:",
    },
    {
      type: "list2",
      list: [
        "Перед тем, как поставить элитный букет в вазу, нужно удалить лишние листья, которые могут оказаться в воде.",
        "Лучше использовать прохладную фильтрованную воду, добавив специальную подкормку для цветов.",
        "Держать букет необходимо подальше от прямых солнечных лучей и отопительных приборов.",
        "Желательно каждый день менять воду, удалять увядшие листья и цветки по мере необходимости.",
      ],
    },
    {
      type: "text",
      text: "Закажите букет в Flowery и убедитесь, что дарить цветы — это легко, удобно и очень приятно!",
    },
    { type: "text", text: "Мы предлагаем:" },
    {
      type: "list",
      list: [
        "несколько видов оплаты — наличными, картой на сайте или курьеру;",
        "круглосуточную доставку в течение 1 часа или к точно обозначенному времени; ",
        "согласование внешнего вида букета перед отправкой;",
        "открытку или записку с пожеланием, также можем распечатать памятное фото.",
      ],
    },
    {
      type: "text",
      text: "Если презент необходимо преподнести анонимно, сохраним отправителя в тайне, а если неизвестен точный адрес для доставки — узнаем его самостоятельно и договоримся об удобном времени. Для этого нам будет достаточно лишь номера телефона получателя.",
    },
    {
      type: "text",
      text: "Профессиональные флористы не оставят наедине с цветочным разнообразием, проконсультируют и помогут с выбором.",
    },
    {
      type: "text",
      text: "Поставки свежих цветов с плантаций Кении и Эквадора 3 раза в неделю и тщательный отбор каждого цветка позволяют радовать клиентов магазина букетами высокого качества.",
    },
  ],
};
