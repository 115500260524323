import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCompositionByStorage as getCompositionByStorageAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  clearUserBasket as clearUserBasketAction,
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";
import Skeleton from "react-loading-skeleton";

import demo1 from "../../assets/demo/19.jpeg";
import demo2 from "../../assets/demo/20.jpeg";
import demo3 from "../../assets/demo/21.jpeg";

import mastercard from "../../assets/payment/mastercard.svg";
import visa from "../../assets/payment/visa.svg";
import mir from "../../assets/payment/mir.svg";
import yandex from "../../assets/payment/yapay.svg";
import sber from "../../assets/payment/sber.svg";
import tinkoff from "../../assets/payment/tinkoff1.svg";
import binance from "../../assets/payment/binance.svg";

import { ReactComponent as SvgClose } from "../../assets/alert/close.svg";

import UiAllGiftsCard from "../cards/UiAllGiftsCard";
import UiBarNum from "../../components/bars/UiBarNum";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheck from "../../components/forms/UiCheck";
import UiCheckList from "../../components/forms/UiCheckList";
import UiFastBuy from "./UiFastBuy";
import UiGiftCard from "../../components/cards/UiGiftCard";
import UiIcon from "../icon/UiIcon";
import UiModal from "./UiModal";
import showNotify from "../../components/notify/UiNotify";

import Env from "../../services/Env";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiModal.scss";
import "./UiModalProduct.scss";

import { convertImages, unCheckInArray } from "../../utilities/array";
import { convertToList, convertToYandexEcom } from "../../utilities/storage";
import { getProductPrice } from "../../utilities/order";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiTextInput from "../forms/UiTextInput";

const UiModalProduct = (props) => {
  let { title } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [item, setItem] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [image, setImage] = useState(0);
  const [type, setType] = useState(null);
  const [selectedNum, setSelectedNum] = useState(1);

  const [fastBuy, setFastBuy] = useState(false);
  const [linkFormActive, setLinkFormActive] = useState(false);

  const demoImages = [demo1, demo2, demo3];

  const [sizes, setSizes] = useState(["11шт", "21шт", "31шт", "51шт", "71шт"]);
  const [productTypes, setProductsTypes] = useState([]);

  const images = [];
  const [gifts, setGifts] = useState([]);
  const [flowers, setFlowers] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [addBasket, setAddBasket] = useState([]);

  const [name, setName] = useState("");
  const [reciever, setReciever] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (title) {
      setLoader(true);
      props.getStorageItem(title).then((val) => {
        if (val) {
          setItem(val);
          setType(null);

          //YANDEX ECOMERCE ADD TO CART
          window.dataLayer.push({
            ecommerce: {
              currencyCode: "RUB",
              detail: {
                products: convertToYandexEcom([val]),
              },
            },
          });

          /*
          props.getSubCategory(val.sub_category).then((res) => {
            if (res.length > 0) setSubCat(res[0]);
          });
     
          props.getCompositionByStorage(val.id).then((res) => {
            if (res.response) {
              if (res.response.length > 0) {
                let arr = [];
                res.response.map((item, index) => {
                  arr.push({
                    option: item.custom_title,
                    price: item.custom_price,
                    text: item.custom_text,
                    storage_id: val.id,
                    image: item.custom_image,
                    id: item.id,
                    index: index + 1,
                     is_active: 0,
                  });
                });
                setProductsTypes(arr);
              }
            }
          });

               */
        }
      });

      props.getStorageListByCat(5).then((val) => {
        setGifts(convertToList(val));
      });
      props.getStorageListByCat(4).then((val) => {
        setFlowers(convertToList(val));
      });
      props.getStorageListByCat(7).then((val) => {
        let arr = val
          .filter((item) => {
            return item.price > 0;
          })
          .map((item) => {
            return {
              option: item.title,
              optionInfo: `+ ${item.price} ₽`,
              item: item,
            };
          })
          .reverse();
        setAdditional(arr);
      });
    }
  }, [location]);

  useEffect(() => {
    if (item) {
      props.getStorageListByCat(item.category).then((val) => {
        setAnalogList(convertToList(val));
      });
      setLoader(false);
    }
  }, [item]);

  var imageButtons = (
    item
      ? !item.images
        ? convertImages(item.imgs)
        : convertImages(item.images)
      : []
  ).map((imgItem, index) => {
    return (
      <button
        key={index}
        className={
          "product-slider-control-button" + (index == image ? " active" : "")
        }
        onClick={() => {
          setImage(index);
          setType(index);
          setItem({ ...item, image: null });
        }}
        style={{ backgroundImage: "url(" + Env.PUBLIC_URL + imgItem + ")" }}
      ></button>
    );
  });

  var sizeList = productTypes.map((sizeItem, index) => {
    return (
      <button
        key={index}
        className={
          "product-size-item" + (item.custom == sizeItem.id ? " active" : "")
        }
        onClick={() => {
          setItem({
            ...item,
            image: convertImages(sizeItem.image)[0],
            images: sizeItem.image,
            custom: sizeItem.id,
            price: sizeItem.price,
            customTitle: sizeItem.option,
            text: sizeItem.text ? sizeItem.text : item.text,
          });
          setImage(0);
        }}
      >
        <div
          className="product-size-item-cover"
          style={{
            backgroundImage:
              "url(" +
              (convertImages(sizeItem.image)[0]
                ? Env.PUBLIC_URL + convertImages(sizeItem.image)[0]
                : demo2) +
              ")",
          }}
        ></div>
        <p>{sizeItem.option}</p>
      </button>
    );
  });

  var tagsList = (item ? item.tags : "")?.split(",").map((item, index) => {
    return (
      <div key={index} className="product-slider-image-status-item">
        <span>{item}</span>
      </div>
    );
  });

  const [analogList, setAnalogList] = useState([]);

  var moreAnalog = analogList.map((item, index) => {
    if (index < 6) return <UiGiftCard key={index} item={item} />;
  });

  var moreFavorite = analogList.map((item, index) => {
    if (index > 6 && index < 12) return <UiGiftCard key={index} item={item} />;
  });

  return (
    <UiModal scroll show={props.modalOpen}>
      <div className="modal-product">
        {item && (
          <Helmet>
            <title>{item.title}</title>
            <meta name="description" content={item.text} />
          </Helmet>
        )}

        <button className="modal-close-btn" onClick={props.modalClose}>
          <SvgClose className="modal-close-btn-icon" />
        </button>
        <div className="product-info">
          {/**
           * image slider
           */}
          <div className="product-slider">
            <div className="product-slider-image-wrap">
              {loader ? (
                <Skeleton className="product-slider-image-overflow" />
              ) : item ? (
                <div className="product-slider-image-overflow">
                  <img
                    className="product-slider-image"
                    src={
                      item?.images
                        ? `${Env.PUBLIC_URL}${
                            convertImages(item.images)[image]
                          }`
                        : `${Env.PUBLIC_URL}${convertImages(item.imgs)[image]}`
                    }
                    alt={item.title}
                    title={item.text}
                  />
                </div>
              ) : null}
              <div className="product-slider-control">
                {loader ? (
                  <Skeleton className="product-slider-control-button" />
                ) : (
                  imageButtons.length > 1 && imageButtons
                )}
              </div>
            </div>
          </div>
          {/**
           * product about info
           */}
          <div className="product-about">
            {!loader && (
              <div className="product-slider-image-status">
                {item?.tags && tagsList}
              </div>
            )}
            <h1>
              {loader ? <Skeleton width={200} /> : item ? item.title : null}
            </h1>
            {item?.article && <p>Артикул: {item?.article}</p>}
            <div className="product-about-price">
              <p>
                {loader ? <Skeleton width={100} /> : item && `${item.price} ₽`}
              </p>
            </div>{" "}
            <div className="product-description">
              {loader ? (
                <Skeleton count={2} width={"100%"} />
              ) : item ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text?.replace(/font-size\s?:([^;]+)/g, ""),
                  }}
                ></p>
              ) : null}
            </div>
            {sizeList.length > 0 && (
              <div className="product-size">
                <h3>Размер букета</h3>
                <p>Эти цветы можно приобрести в другом количестве:</p>
                <div className="product-size-list">{sizeList}</div>
              </div>
            )}
            {loader ? (
              <Skeleton count={1} width={"100%"} />
            ) : (
              <div className="product-all-gifts">
                <h4>Заказав этот букет, вы получите в подарок</h4>
                <UiAllGiftsCard />
              </div>
            )}
            <div className="product-about-sale">
              <UiIcon fill="pink" icon="gift" size={16} />
              <h5>Вы получите бонус за этот букет:</h5>
              <p>
                <strong>
                  &nbsp;{item && Math.round(item.price * 0.03)}&nbsp;₽
                </strong>
              </p>
            </div>
            <div className="product-link-generate">
              <button
                onClick={() => setLinkFormActive(true)}
                className="product-link-generate-btn"
              >
                <span>Намекнуть о подарке</span>
              </button>
            </div>
            {/*
            <div className="product-type">
              <h3>Дополнение к букету</h3>
              <UiCheckList
                border
                colored={true}
                checkList={additional}
                label="К этому букету идеально подойдет:"
                onChange={(val) => {
                  //setImage(val);
                  setType(type == val ? null : val);
                }}
                selected={type}
              />
              <span>
                * При отключении всех дополнений, цветы будут перевязаны
                стандартной лентой
              </span>
            </div>
              */}
            <div className="product-add">
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : props.checkInUserBasket(item ? item.id : null) ? (
                <UiBtnColor
                  color="opal"
                  text={"Заказать"}
                  onClick={() => {
                    navigate("/order");
                  }}
                />
              ) : item?.is_available == 1 && item?.is_active == 0 ? (
                <UiBtnColor
                  color="pink"
                  text={"Добавить в корзину"}
                  onClick={() => {
                    window.ym(92961160, "reachGoal", "add-flower");

                    //YANDEX ECOMERCE ADD TO CART
                    window.dataLayer.push({
                      ecommerce: {
                        currencyCode: "RUB",
                        add: {
                          products: convertToYandexEcom([item]),
                        },
                      },
                    });

                    item.count = selectedNum;
                    props.addUserBasket(item);
                    if (type) {
                      setAddBasket(
                        unCheckInArray(addBasket, additional[type].item)
                      );
                    }
                    showNotify(`Добавлено: ${item.title}`);
                  }}
                />
              ) : (
                <UiBtnColor
                  color="gray"
                  text={"Не доступно"}
                  onClick={() => {}}
                />
              )}
            </div>
            <div className="product-add">
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : (
                item?.is_active == 0 && (
                  <UiBtnColor
                    color="gray"
                    text={"Купить в один клик"}
                    onClick={() => {
                      window.ym(92961160, "reachGoal", "fast-buy");

                      props.clearUserBasket();
                      item.count = selectedNum;
                      props.addUserBasket(item);
                      if (type) {
                        setAddBasket(
                          unCheckInArray(addBasket, additional[type].item)
                        );
                      }
                      //props.modalClose();
                      setFastBuy(true);
                    }}
                  />
                )
              )}
            </div>
            <p>Способы оплаты:</p>
            <div className="product-payment-types">
              <img alt="MasterCard" width={30} height={16} src={mastercard} />
              <img alt="Visa" width={30} height={16} src={visa} />
              <img alt="Мир" width={30} height={16} src={mir} />
              <img alt="Yandex Pay" width={40} height={16} src={yandex} />
              <img alt="Сбер Pay" width={40} height={16} src={sber} />
              <img alt="Тинькофф" width={60} height={16} src={tinkoff} />
              <img alt="Binance" width={60} height={16} src={binance} />
            </div>
            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={"100%"}
                height={20}
              />
            ) : (
              item?.category != 5 && (
                <div className="product-description">
                  <h6>Наличие и внешний вид</h6>
                  {subCat?.description_availability ? (
                    <p>{subCat?.description_availability}</p>
                  ) : item?.category != 1 ? (
                    <p>
                      Состав букета и упаковка могут быть изменены в зависимости
                      от ассортимента на текущий момент. При этом мы гарантируем
                      сохранить цветовую гамму и стиль букета.
                    </p>
                  ) : (
                    <p>
                      Состав букета и упаковка могут быть изменены в зависимости
                      от ассортимента на текущий момент. При этом мы гарантируем
                      сохранить цветовую гамму и стиль букета.
                      <br />
                      <br />
                      Данный букет уже собран и готов к доставке. Если вы
                      желаете собрать подобный букет на свой бюджет, обратитесь
                      к нашим менеджерам по телефону.
                    </p>
                  )}

                  {subCat?.description_care && <h4>Как ухаживать</h4>}
                  {subCat?.description_care && (
                    <p>{subCat?.description_care}</p>
                  )}
                  {subCat?.description_whom && <h4>Кому можно дарить</h4>}
                  {subCat?.description_whom && (
                    <p>{subCat?.description_whom}</p>
                  )}
                </div>
              )
            )}
          </div>
        </div>
        <div className="product-info-guarantee">
          <div className="product-info-guarantee-card">
            <p>ЦВЕТЫ ПРОСТОЯТ ОТ 5 ДНЕЙ ИЛИ ЗАМЕНИМ БУКЕТ!</p>
          </div>
          <div className="product-info-guarantee-card">
            <p>ФОТО БУКЕТА ПЕРЕД ОТПРАВКОЙ</p>
          </div>
          <div className="product-info-guarantee-card">
            <p>ДОСТАВКА ОТ 1 ЧАСА</p>
          </div>
          <div className="product-info-guarantee-card">
            <p>ФИРМЕННАЯ ОТКРЫТКА В ПОДАРОК</p>
          </div>
        </div>
        <div className="product-info-more">
          <h3>Аналогичные товары</h3>
          <div className="product-info-more-list">{moreAnalog}</div>
        </div>
        <div className="product-info-more">
          <h3>Вам понравится</h3>
          <div className="product-info-more-list">{moreFavorite}</div>
        </div>
      </div>

      {/** fast buy */}
      <div className={`modal-product-fast-buy ${fastBuy ? " active" : ""}`}>
        <UiFastBuy
          modalOpen={fastBuy}
          modalClose={() => {
            setFastBuy(false);
          }}
        />
      </div>
      {/** request product */}
      <div
        className={`modal-product-link-generate ${
          linkFormActive ? " active" : ""
        }`}
      >
        <div className="modal-product-link-form">
          <div className="modal-product-link-header">
            <h2>Намекни другу о подарке</h2>
            <UiBtnIcon
              onClick={() => setLinkFormActive(false)}
              fill="gray"
              icon="close"
              size={20}
            />
          </div>
          <div className="modal-product-link-body">
            <p>
              Нашли что-нибудь особенное? Пришлем другу сообщение с подсказкой!
            </p>
            {item ? (
              <div className="modal-product-link-image-overflow">
                <img
                  className="modal-product-link-image"
                  src={
                    item?.images
                      ? `${Env.PUBLIC_URL}${convertImages(item.images)[image]}`
                      : `${Env.PUBLIC_URL}${convertImages(item.imgs)[image]}`
                  }
                  alt={item.title}
                  title={item.text}
                />
                <h2>{item.title}</h2>
                <div className="product-about-price">
                  <p>{`${item.price} ₽`}</p>
                </div>
              </div>
            ) : null}
            <UiTextInput label="Ваше имя *" onChange={(val) => setName(val)} />
            <UiTextInput label="Имя получателя *" onChange={(val) => setReciever(val)} />
            <UiTextInput label="Номер телефона получателя в WhatsApp *" onChange={(val) => setPhone(val)} />
            <UiBtnColor
              color="pink"
              onClick={() => { 
                
                let text = `${name} хотел бы получить в подарок ${item.title}`
                window.location.href =
                `https://api.whatsapp.com/send/?phone=${reciever}&text=${text}&type=phone_number&app_absent=0`;

                setLinkFormActive(false); 
              
              }}
              text="Отправить"
            />
          </div>
        </div>
      </div>
    </UiModal>
  );
};
export default connect(
  ({ storage, login, category }) => ({ storage, login, category }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,
    getCompositionByStorage: getCompositionByStorageAction,
    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    clearUserBasket: clearUserBasketAction,
  }
)(UiModalProduct);
