import React, { useState } from "react";
import { connect } from "react-redux";
import { addFastOrder as addFastOrderAction } from "../../redux/modules/order";
import { addFile } from "../../services/Storage";

import OrderConstant from "../../constants/OrderConstant";

import Container from "../container/Container";

import "./UiFooter.scss";

import UiTextInputMasked from "../forms/UiTextInputMasked";
import UiTextArea from "../forms/UiTextArea";
import UiCheck from "../forms/UiCheck";
import UiBtnColor from "../buttons/UiBtnColor";

import { formatDate } from "../../utilities/date";
import showNotify from "../../components/notify/UiNotify";

const UiFooterPhotoOrder = (props) => {
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const [warnPhone, setWarnPhone] = useState(false);
  const [agree, setAgree] = useState(false);

  function trimPhone(_trim) {
    var _phone = _trim.replace(/ /g, "");
    _phone = _phone.replace(/_/g, "");
    _phone = _phone.replace(/\+7/g, "7");
    _phone = _phone.replace(/\(/g, "");
    _phone = _phone.replace(/\)/g, "");
    _phone = _phone.replace(/-/g, "");
    return _phone;
  }

  function pay() {
    if (phone.length > 5) {
      let status = OrderConstant.ORDER_FEEDBACK_STATUS;
      let type = OrderConstant.ORDER_ONLINE_TYPE;
      let store = OrderConstant.ORDER_SHIPMENT_STORE[0];
      var _phone = trimPhone(phone);

      var _text = `Прошу подобрать букет по фото ${image} ${comment}`;
      props
        .addFastOrder(
          {
            address_a: "",
            date: formatDate(),
            time: "",
            moving_types: 1,
            payment_type: 1,
            order_type: type,
            order_status: status,
            shipment_store: store,
            price: 0,
            price_discount: 0,
            price_delivery: 0,
            user_phone: _phone,
            user_name: "",
            postcard: "",
            text: _text,
            comment: _text,
            user_call: 1,
          },
          []
        )
        .then((res) => {
          if (res) {
            setAgree(false);
            setImage(null);
       
            showNotify(
              `Спасибо за ваше обращение ! Мы обязательно перезвоним вам!`
            );
          }
        });
    }
  }

  return (
    <div className="feedback">
      <Container>
        <div className="feedback-form-wrap">
          <div className="feedback-form-info">
            <h2>Хотите заказать букет по фото?</h2>
            <ul>
              <li>Не нашли нужного букета на сайте?</li>
              <li>Хотите собрать свой индивидуальный букет?</li>
              <li>У Вас есть фото букета?</li>
              <li>Нужна другая цветовая гамма или редкие цветы?</li>
            </ul>
            <p>
              Для Вас есть уникальное предложение: оставьте заявку, опишите
              состав, цвет желаемого букета или прикрепите фото и мы создадим
              букет по Вашим пожеланиям!
            </p>
            <p>
              Просто укажите Ваш номер телефона, опишите букет или приложите
              фото.
            </p>
            <p>
              Оставьте заявку и мы свяжемся с Вами в ближайшее время для
              создания Вашей композиции.
            </p>
          </div>
          <div className="feedback-form">
            <UiTextInputMasked
              label="Номер телефона"
              value={phone}
              onChange={(val) => {
                setPhone(val);
                setWarnPhone(trimPhone(val).length <= 10);
              }}
              warnText={warnPhone && "Введите номер телефона"}
            />
            <UiTextArea
              placeholder="Опишите Ваш букет. Цвет, состав, дата и время доставки..."
              rows="5"
              onChange={(val) => {
                setComment(val);
              }}
            />
            <label className="input-file">
              <input
                type="file"
                name="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => { 
                  if (e.target.files.length > 0) {
                    addFile(e.target.files[0]).then((res) => {
                      if (res.response) setImage(res.response);
                    });
                  }
                }}
              />
              <span>{image ? image : "Выберите файл"}</span>
            </label>

            <UiCheck
              label="Я принимаю условия пользовательского соглашения"
              onChange={(val) => setAgree(val)}
            />
            <div className="feedback-form-button">
              <UiBtnColor
                color="pink"
                small
                text="Оставить заявку"
                disabled={!agree}
                onClick={() => {
                  if (agree) {
                    pay();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default connect(({ order }) => ({ order }), {
  addFastOrder: addFastOrderAction,
})(UiFooterPhotoOrder);
