import OrderConstant from "../constants/OrderConstant";

export const getInfo = (item) => {
    const volumeArray = OrderConstant.VOLUME_ARRAY;
    const trashArray = OrderConstant.TRASH_ARRAY
    const takArray = OrderConstant.TAK_ARRAY;
    const unbuildArray = OrderConstant.UNBUILD_ARRAY;
    const packArray = OrderConstant.PACK_ARRAY;
    const liftTypes = OrderConstant.LIFT_TYPES;
    
    const prr_types =  OrderConstant.PRR_TYPE;
    let arr = [];
    arr.push({ text: volumeArray[item.volume_car].text });
    arr.push( { text: `${item.movers} грузчика` });

    arr.push({ text: prr_types[item.prr_types].text });
    if(item.moving_types != 4) {
        if(item.is_hard_work) arr.push({ text: takArray[item.tak_value].text });
        if(item.is_assembly_disassembly)arr.push({ text: unbuildArray[item.unbuild_value].text });
        if(item.additional_work)arr.push({ text: packArray[item.pack_value].text });
    } else {
        arr.push({ text: trashArray[item.trash_value].text });
    }
    
    return arr;
}

export const getProductPrice = (_item, _count) => {
    let sum = 0;
    if(_item.sale == 0) { 
        sum = sum + _item.price * _count;
    } else {
        let _c = _count;
        sum = sum + ( _c >= _item.sale_count ? _item.sale : _item.price) * (_c);
    }
    return sum;
}


export const getPrice = (_item) => {
    let sum = 0;
    if(_item.sale == 0) { 
        sum = sum + _item.price * (_item.count ? _item.count: 1);
    } else {
        let _c = _item.count ? _item.count: 1;
        sum = sum + ( _c >= _item.sale_count ? _item.sale : _item.price) * (_c);
    }
    return sum;
}

export const getTotalPrice = (_arr, _delivery = 0, _discount = 0, _onlyArr = false) => {
    let sum = 0;
    _arr.map((item, index) => {
        if(item.sale == 0) { 
            sum = sum + item.price * (item.count ? item.count: 1);
        } else {
            let _c = item.count ? item.count: 1;
            sum = sum + ( _c >= item.sale_count ? item.sale : item.price) * (_c);
        }
    }) 
  
    if(!_onlyArr){
        if(_delivery) sum = sum + parseFloat(_delivery); 
        sum = sum - parseFloat(_discount);
    } 

    return Math.round(sum);
}