import React, { useRef, useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import UiReviewCard from "../cards/UiReviewCard";

import "./UiSliderReviews.scss";
import UiIcon from "../icon/UiIcon";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  640: { items: 2 },
  1024: { items: 3 },
};

const items = [
  <div className="slider-reviews-item" data-value="1">
    <UiReviewCard
      name="Inna C."
      status="26 августа 2023"
      rating="5"
      review="Спасибо огромное за шикарные букеты! Оператор очень вежливая, приятная девушка! Сделали все максимально качественно! Цветы пережили перелет на самолете в богажном отсеке без воды, и остались такими же свежими, с учетом того, что на улице была дастаточно жаркая погода. Очень понравилось, что в каждом букете есть конверт с рекомендацией по уходу за цветами и гранулами для воды, чтобы цветы стояли дольше! Очень рекомендую данный цветочный магазин! Спасибо за Ваш профессионализм!"
    />
  </div>,
  <div className="slider-reviews-item" data-value="2">
    <UiReviewCard
      name="Сергей О."
      status="4 ноября 2023"
      rating="5"
      review="Недавно открыл для себя и свей любимой замечательный магазин свежих цветов. Ассортимент поражает своим разнообразием: от классических роз и тюльпанов до экзотических орхидей и эвкалипта. Все цветы свежие, буквально наполнены жизнью и ароматом."
    />
  </div>,
  <div className="slider-reviews-item" data-value="3">
    <UiReviewCard
      name="Диана Острошенко"
      status="29 сентября 2023"
      rating="5"
      review="Спасибо большое за красивый букет и за фонтан шаров. Собирала мужу на день рождение, получился цветочно-брутальный сюрприз) Всё достаточно быстро подобрали и со вкусом. Отдельное спасибо за доставку) Курьер как часы, помог все занести в подъезд. Получила удовольствие от общения пока выбирали шары и букет, девушка очень приятная и вежливая. Обязательно обращусь к вам снова. 💥"
    />
  </div>,
  <div className="slider-reviews-item" data-value="4">
    <UiReviewCard
      name="Сергей"
      status="29 сентября 2023"
      rating="5"
      review="Покупаю цветы всегда исключительно у Flowery. Никогда не подводят. Делают букеты исключительно из свежих цветов. С радостью помогут подобрать цветы к любому поводу очень быстро. Проконсультируют удалённо. Очень удобно. Не надо убегать с работы, совещания. Заказал, согласовал и оплатил онлайн. И всё это без поездок в обед или часы пик по пробкам."
    />
  </div>,
  <div className="slider-reviews-item" data-value="5">
    <UiReviewCard
      name="Ольга Васильевна"
      status="27 ноября 2023"
      rating="5"
      review="Всегда вежливые и учтивые девочки. Помогут определиться с выбором, если есть сомнения. Букеты всегда шикарнае. Цветы свежие. Беру не в первый раз, только самые положительные отзывы! На день матери 26.11.2023 девочки очень быстро нашли мне прекрасный букетик для моей мамы. Срасибо им огромное за такую оперативность."
    />
  </div>,
  <div className="slider-reviews-item" data-value="6">
    <UiReviewCard
      name="Екатерина Зиновьева"
      status="14 сентября 2023"
      rating="5"
      review="Очень красивые цветы, заказываю не в первый раз ☺️ и всегда советую заказывать цветы именно в мастерской Flowery 🌺 Удобный сайт, вежливые и отзывчивые консультанты и операторы ☺️ И ассортимент прекрасный ❤️ Flowery, Вы - просто умнички! 👍 Желаю вам развития и новых интересных флористических идей 🌟"
    />
  </div>,
  <div className="slider-reviews-item" data-value="7">
    <UiReviewCard
      name="эльвира николаева"
      status="26 ноября 2023"
      rating="5"
      review="Всегда заказываю букеты во flowery-sakh, удобный интерфейс, всегда вежливые и отзывчивые специалисты, расскажут, покажут, ничего не скроют 😁 Цветы свежие, и долгостойкие 💐 Ребят, спасибо за Ваш труд, развивайтесь дальше в том же направлении 🤗 А я за букетиком 🤭"
    />
  </div>,
  <div className="slider-reviews-item" data-value="8">
    <UiReviewCard
      name="Анастасия К."
      status="13 августа 2023"
      rating="5"
      review="Сайт плохо работает в части связи с компанией. В остальном всё супер! Операторы на ватсап раьотают отлично. Всё подскажут, расскажут, подберут. Соотношение цена-качество лучшее, доставка бесплатная тоже радует. Всё доставили в срок, букеты красивые. Рекомендую!"
    />
  </div>,
  <div className="slider-reviews-item" data-value="9">
    <UiReviewCard
      name="Павел Василискин"
      status="16 сентября 2023"
      rating="5"
      review="Отличный цветочный магазин, большой выбор готовых букетов на сайте, приветливый и отзывчивый персонал) Очень быстро отвечают, помогают, и цветочки всегда отличные, свежие, очень долго стоят)"
    />
  </div>,
  <div className="slider-reviews-item" data-value="10">
    <UiReviewCard
      name="Жекич Щ."
      status="18 сентября 2023"
      rating="5"
      review="Всем привет. Понадобилось срочно заказать красивый букетик цветов. Обратился в эту компанию. Быстро оформили букет. Вежливый персонал. Рассказали о дополнительных возможностях для поздравления. Сделали скидку. Молодцы! Спасибо вам большое!"
    />
  </div>,
];

const UiSliderReviews = (props) => {
  return (
    <div className="slider-reviews-wrap">
      <h2>Отзывы о магазинах Flowery</h2>
      <div className="slider-reviews-title">
        <p>Последние отзывы о доставке цветов в Южно-Сахалинске</p>
      </div>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
      />
    </div>
  );
};
export default UiSliderReviews;
