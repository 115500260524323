import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { connect } from "react-redux";
import { getTopMenuCategoryList as getTopMenuCategoryListAction } from "../../../redux/modules/category";

import { retrieveData } from "../../../services/Storage";
import { convertImages } from "../../../utilities/array";

import Container from "../../../components/container/Container";

import "./UiHomeCategories.scss";
import Env from "../../../services/Env";

const UiHomeCategories = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);

  const responsive = {
    0: { items: 2 },
    640: { items: 4 },
    1024: { items: 5 },
    1366: { items: 8 },
  };

  useEffect(() => {
    props.getTopMenuCategoryList().then((res) => {
      let arr = res.map((c) => {
        return {
          href:
            c.sub_list.length > 0
              ? `/group/${c.title_url}`
              : `/catalog/${c.title_url}`,
          title: c.title,
          image: c.image.length > 0 ? convertImages(c.image[0]?.imgs) : null,
        };
      });
      setCategoriesList(arr);
    });
  }, []);

  var categories = categoriesList.map((item, index) => {
    return (
      <a
        key={index}
        className="category-card"
        href={item.href}
        data-value={index + 1}
      >
       {item.image && <img
          alt={item.title}
          src={Env.PUBLIC_URL + item.image}
          title={item.title}
        /> }
        <div className="category-card-shadow">
          <div>
            <h3>{item.title}</h3>
          </div>
        </div>
      </a>
    );
  });

  return (
    <section className="home-categories-wrap">
      <Container>
        <div className="home-categories">
          <AliceCarousel
            mouseTracking
            items={categories}
            responsive={responsive}
          />
        </div>
      </Container>
    </section>
  );
};
export default connect(({}) => ({}), {
  getTopMenuCategoryList: getTopMenuCategoryListAction,
})(UiHomeCategories);
