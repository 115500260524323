import React from "react";

import UiRating from "../bars/UiRating";

import "./UiReviewCard.scss";

const UiReviewCard = (props) => {
  return (
    <div className="review-card">
      <div className="review-card-title">
        <div
          className="review-card-image"
          style={{ backgroundImage: "url(" + props.image + ")" }}
        >
          <p>{props.name[0].toUpperCase()}</p>
        </div>
        <div className="review-card-info">
          <h4>{props.name}</h4>
          {props.status && <p>{props.status}</p>}
        </div>
      </div>
      <div className="review-card-rating">
        <UiRating rating={props.rating} />
        <p>{props.date}</p>
      </div>
      <div className="review-card-text">
        <p>{props.review}</p>
      </div>
    </div>
  );
};
export default UiReviewCard;
