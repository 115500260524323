import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { unCheckInArrayKey } from "../../utilities/array";
import { retrieveData, storeData } from "../../services/Storage";

import UiBtnIcon from "../../components/buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiCatalogCard.scss";

const UiCatalogCard = (props) => {
  const [favorite, setFavorite] = useState(false);

  if (!props.skeleton && props.status?.length > 0) {
    var statuses = props.status.map((item, index) => {
      return (
        <p
          key={index}
          className={
            "status" +
            (item == "Новинка"
              ? " pink"
              : item == "Хит продаж"
              ? " orange"
              : item == "Авторский"
              ? " green"
              : " opal")
          }
        >
          {item}
        </p>
      );
    });
  }

  if (!props.skeleton && props.sizeList?.length > 0) {
    var sizes = props.sizeList.map((item, index) => {
      return (
        <button key={index} className="active">
          {item}
        </button>
      );
    });
  }

  function addToFavorite(_item){
    let arr = retrieveData("FLOWERY_FAVORITE");
    if(!arr) arr = [];
    arr = unCheckInArrayKey(arr, _item, "id"); 
    storeData("FLOWERY_FAVORITE", arr);
  }

  return (
    <div
      className={"catalog-card" + (props.search ? " search" : "")}
      style={props.cardWidth ? { width: props.cardWidth } : null}
    >
      <div className="catalog-card-image">
        <div className="catalog-card-image-wrap">
          {props.skeleton ? (
            <Skeleton className="catalog-card-image-wrap" />
          ) : (
            <LazyLoadImage
              onClick={props.onClick}
              alt={props.title}
              title={props.title}
              effect="blur"
              src={
                props.image
                /* window.innerWidth < 768
                  ? props.image.replace(".webp", "-min-mob.webp")
                  : props.image.replace(".webp", "-min.webp")
                  */
              }
            />
          )}
        </div>
        <div className="catalog-card-controls">
          <div className="catalog-card-statuses">
            {!props.skeleton && props.status?.length > 0 && statuses}
          </div>
          {!favorite ? (
            <div className="catalog-card-favorite">
              <UiBtnIcon
                color={"white"}
                hover="pink"
                icon="heart"
                fill="dark"
                round
                iconSize={20}
                size={36}
                onClick={() => {
                  setFavorite(true);
                  addToFavorite(props.item)
                }}
              />
            </div>
          ) : (
            <UiBtnIcon
              color={"pink"}
              hover="pink"
              icon="heart"
              fill="white"
              round
              iconSize={20}
              size={36}
              onClick={() => {
                setFavorite(false);
                addToFavorite(props.item)
              }}
            />
          )}
        </div>
        {!props.skeleton && props.sizeList?.length > 0 && (
          <div className="catalog-card-sizes">{sizes}</div>
        )}
      </div>
      <div className="catalog-card-info">
        <div className="catalog-card-info-wrap">
          <h3>
            {props.skeleton ? <Skeleton count={1} width={136} /> : props.title}
          </h3>
          <div
            className={
              "catalog-card-price" +
              (props.oldPrice ? " catalog-card-price-sale" : "")
            }
          >
            {props.skeleton ? (
              <Skeleton count={1} width={60} />
            ) : (
              <p>
                {props.oldPrice && <span>{props.oldPrice}&nbsp;₽</span>}
                {props.oldPrice && <b>&nbsp;&nbsp;&nbsp;</b>}
                {props.price}&nbsp;₽
                { props.item?.is_available == 1 && props.item?.is_active == 0 ? 
                  <button className="catalog-card-price-btn" >
                    Не доступно
                  </button>
                :
                <button
                  className="catalog-card-price-btn"
                  onClick={() => {
                    props.onFastClick();
                  }}
                >
                  Купить
                </button>
                }
              </p>
            )}
            {props.search ? null : props.skeleton ? (
              <Skeleton width={80} height={32} />
            ) : (
              <UiBtnIcon
                color="blue"
                hover="pink"
                icon="bag"
                fill="white"
                round
                iconSize={20}
                size={36}
                onClick={props.onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UiCatalogCard;
