import React, { useState } from "react";

import "./UiRating.scss";

import { ReactComponent as StarIcon } from "../../assets/ui/star-outline.svg";
import { ReactComponent as StarActiveIcon } from "../../assets/ui/star.svg";

export default function UiRating(props) {
  const [rating, setRating] = useState(null);

  const ratingUpdate = () => {
    if (props.rating !== undefined && props.rating && rating === null) {
      setRating(props.rating);
    }
  };

  ratingUpdate();

  const ratingPress = (value) => {
    let newRating = value;
    setRating(newRating);
    props.callBack(newRating);
  };

  return (
    <div className="rating-wrap">
      {props.active ? (
        <div className="rating">
          <button className="rating-button" onClick={() => ratingPress(1)}>
            {rating > 0 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </button>
          <button className="rating-button" onClick={() => ratingPress(2)}>
            {rating > 1 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </button>
          <button className="rating-button" onClick={() => ratingPress(3)}>
            {rating > 2 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </button>
          <button className="rating-button" onClick={() => ratingPress(4)}>
            {rating > 3 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </button>
          <button className="rating-button" onClick={() => ratingPress(5)}>
            {rating > 4 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </button>
        </div>
      ) : (
        <div className="rating">
          <div className="rating-button">
            {props.rating > 0 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </div>
          <div className="rating-button">
            {props.rating > 1 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </div>
          <div className="rating-button">
            {props.rating > 2 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </div>
          <div className="rating-button">
            {props.rating > 3 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </div>
          <div className="rating-button">
            {props.rating > 4 ? (
              <StarActiveIcon className="img star-active" />
            ) : (
              <StarIcon className="img star" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
