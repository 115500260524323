import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ReactComponent as SvgCheck } from "../../assets/alert/check.svg";

import { formatDate } from "../../utilities/date";
import { getPrice, getTotalPrice } from "../../utilities/order";

import { addFastOrder as addFastOrderAction } from "../../redux/modules/order";
import {
  getStoredBasket as getStoredBasketAction,
  clearUserBasket as clearUserBasketAction,
} from "../../redux/modules/login";

import OrderConstant from "../../constants/OrderConstant";

import UiBtnColor from "../buttons/UiBtnColor";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiTextInput from "../forms/UiTextInput";
import UiTextInputMasked from "../forms/UiTextInputMasked";
import UiModal from "./UiModal";

import "./UiModal.scss";
import "./UiFastBuy.scss";

const UiFastBuyModal = (props) => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [warnName, setWarnName] = useState(false);
  const [phone, setPhone] = useState("");
  const [warnPhone, setWarnPhone] = useState(false);

  const [basketList, setBasket] = useState([]);

  useEffect(() => {
    if (props.modalOpen) {
      setStep(0);
      setName("");
      setPhone("");
      setBasket(props.getStoredBasket());
    }
  }, [props.modalOpen]);

  function pay() {
    if (name.length > 1 && phone.length > 5) {

      let status = OrderConstant.ORDER_ONLINE_STATUS;
      let type = OrderConstant.ORDER_ONLINE_TYPE;
      let store = OrderConstant.ORDER_SHIPMENT_STORE[0];

      var _phone = phone.replace(/ /g, "");
      _phone = _phone.replace(/\+7/g, "7");
      _phone = _phone.replace(/\(/g, "");
      _phone = _phone.replace(/\)/g, "");
      _phone = _phone.replace(/-/g, "");

      props
        .addFastOrder(
          {
            address_a: "",
            date: formatDate(),
            time: "",
            moving_types: 1,
            payment_type: 1,

            order_type: type,
            order_status: status,
            shipment_store: store,
            
            price: getTotalPrice(basketList, 0, 0, false),
            price_discount: 0,
            price_delivery: 0,
            user_phone: _phone,
            user_name: name,
            postcard: "",
            text: "",
            comment: "",
            user_call: 1,
          },
          basketList
        )
        .then((res) => {
          if (res) {
            props.clearUserBasket();
            setStep(1);
          }
        });
    }
  }

  return (
    <UiModal show={props.modalOpen}>
      <div className={"fast-buy"}>
        <div className={"fast-buy-form-wrap" + (step === 0 ? " active" : "")}>
          {step === 0 && (
            <div className="fast-buy-wrap">
              <div className="fast-buy-close">
                <UiBtnIcon
                  fill="blue"
                  icon="close"
                  iconSize={20}
                  onClick={() => props.modalClose()}
                  size={40}
                />
              </div>
              <h2>Купить цветы в один клик:</h2>
              <div className="fast-buy-form">
                <UiTextInput
                  label="Ваше имя"
                  value={name}
                  onChange={(val) => {
                    setName(val);
                    setWarnName(val.length == 0);
                  }}
                  onBlur={(val) => {
                    setWarnName(val.length == 0);
                  }}
                  warnText={warnName && "Введите ваше имя"}
                />
                <UiTextInputMasked
                  label="Номер телефона"
                  value={phone}
                  onChange={(val) => {
                    setPhone(val);
                    setWarnPhone(val.length <= 7);
                  }}
                  warnText={warnPhone && "Введите номер телефона"}
                />
                <UiBtnColor
                  color="pink"
                  text="Купить в один клик"
                  onClick={() => pay()}
                />
              </div>
            </div>
          )}
        </div>

        <div className={"fast-buy-check-wrap" + (step === 1 ? " active" : "")}>
          {step === 1 && (
            <div className="fast-buy-wrap">
              <SvgCheck
                className="fast-buy-success-icon"
                width={64}
                height={64}
              />
              <h3>Ваш заказ передан в работу!</h3>
              <p>
                Через некоторое время с Вами свяжется наш менеджер для
                подтверждения и уточнения деталей заказа.
              </p>
              <UiBtnColor
                color="opal"
                text="Я жду!"
                onClick={() => {
                  props.modalClose();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </UiModal>
  );
};

export default connect(({ order }) => ({ order }), {
  getStoredBasket: getStoredBasketAction,
  clearUserBasket: clearUserBasketAction,
  addFastOrder: addFastOrderAction,
})(UiFastBuyModal);
